import React from 'react';
import reportWebVitals from './reportWebVitals';
import Navigation from './navigation';
import WOW from 'wow.js';
import './resources/scss/main.scss';

import { createRoot } from 'react-dom/client';

function App() {
	new WOW({
		boxClass: 'wow', // default
		animateClass: 'animated', // default
		offset: 0, // default
		mobile: true, // default
		live: true, // default
	}).init();
	return <Navigation />;
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
